import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { AccountNavMenuAppContainer } from '@mojang/account-nav-menu';

const target = document.getElementById('app-account-nav-menu');

ReactDOM.render(
  <AccountNavMenuAppContainer />,
  target
);
